// Constants
$block-color-bg: #eeeeee;
$full-row-color-bg: darken($block-color-bg, 5%);
$half-row-color-bg: #f7f7f7;
$info-text: #aaaaaa;
$admin-box: #dddddd;

$nav-bg-color: #000000;
$nav-bg-color-hover: lighten($nav-bg-color,20%);
$nav-bg-color-active: #FFFFFF;
$nav-link-color: #ffffff;
$nav-subnav-link-color: #000000;
$nav-link-color-hover: $nav-link-color;
$nav-link-color-active: #000000;
$nav-tab-top-color: darken($nav-link-color, 50%);

$link-color: #0000ff;

// Message colors
$error-bg: #FF8A65;
$error-color: #BF360C;
$error-border: #BF360C;
$success-bg: #C8E6C9;
$success-border: #388E3C;
$success-color: #388E3C;
$subheader-color: #555555;

$stripes-1: #FFEB3B;
$stripes-2: #FFF9C4;

// Icons
$icon-bg: #cccccc;
$icon-fg: #B3B3B3;

// Buttons
$btn_default: #3EB0EB;
$btn_delete: #FF192D;

// Taken from https://coolors.co/1779ba-767676-ffae00-cc4b37-009e0f
$cta-green: #009E0F;
