label {
    display: block;
}

.required {
    font-size: 12px;
    color: $error-color;
    font-style: italic;
}

.columns.nopadding {
    padding: 0;
}

.form-block {
    &.placeholder { visibility: hidden; }
    &.checkbox, &.confirmation_checkbox {
        position: relative;
        label {
            display: inline-block;
            position: absolute;
            top: 0;
            left: 30px;
        }
        input[type="checkbox"] {
            display: inline-block;
            position: absolute;
            top: 5px;
        }
    }
    p {
        margin-bottom: 1rem;
    }
    &.preview, .print {
        &.email_enabled { visibility: hidden; }
        p {
            margin: 0;
            padding: 0;
            &.formlabel {
                color: #aaaaaa;
            }
        }
    }
    &.print {
        min-height: 44px;
        border: 1px solid #aaaaaa;
        margin-bottom: 10px;
        p {
            line-height: normal;

        }
        .formlabel {
            margin: 0;
            font-size: 12px;
            color: #aaaaaa;
        }
        .value {
            margin: 10px 0;
        }
    }
    .side-text {
        // Text next to an input field. height is copied from _select.scss > @mixin form-select
        line-height: ($input-font-size + ($form-spacing * 1.5) - rem-calc(1));
    }
}
.pre-info-btn, .info-btn {
    display: inline-block;
    cursor: help;
}

.helptext-inline {
    // Remove spacing between form field and help text
    input {
        margin-bottom: 0;
    }
    .help-text {
        display: inline-block;
        margin-bottom: 1rem;
    }
}

input.date_span {
    width: 40% !important;
}

ul.radio-group {
    list-style: none;
    padding: 0;
    margin: 0;

}

#contact_form ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

@media print {
    .applform-block {
        a {
            text-decoration: none;
        }
        a[href]:after {
            content: none !important;
        }

    }
}
