@import "colors";
@import "utils";
@import "foundation/settings";
@import "foundation/foundation";

.applform-wizard {
    position: relative;
    width: 100%;
    margin-left: 0;
    display: table;
    width: 100%;

    li {
        // display: table-cell;
        line-height: 1.5;
        position: relative;
        background-color: $lighter-gray;
        margin-right: 10px;
        width: auto;
        border-right: 10px solid white;
        padding: 1em 0 1em 35px;
        a {
            padding: 1em 0;
        }
        &:first-child {
            padding: 1em 0 1em 10px;
        }
        &:last-child {
            border-right: 0;
        }
        white-space: nowrap;

        &:not(:last-child):after {
            content: '';
            border-left: 28px solid $lighter-gray;
            border-top: 28px solid transparent;
            border-bottom: 28px solid transparent;
            position: absolute;
            top: 0;
            left: 100%;
            z-index: 20;
            margin-left: -1px; // Fix spacing between li and arrow
        }
        &:not(:first-child):before {
            content: '';
            border-left: 28px solid #fff;
            border-top: 28px solid transparent;
            border-bottom: 28px solid transparent;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
        }
        &.active {
            background-color: get-color(warning);
            a {
                color: $white;
            }
            &:after {
                border-left-color: get-color(warning);
            }
        }
    }
}

@include breakpoint(small) {
    .applform-wizard li {
        display: inline-block;
        border-right: 0;
        margin-bottom: 10px;
        &:last-child {
            padding-right: 10px;
        }
    }
}

/* Large and up */
@media screen and (min-width: 64em) {
    .applform-wizard li {
        display: table-cell;
        border-right: 10px solid white;
    }
}


.applform-block {
    position: relative;
    background: $lighter-even-gray;
    margin: 0 0 20px 0;
    padding: 20px;
    border-radius: 7px;
    &.full-row {
        background: $full-row-color-bg;
    }

    &.name-medium {
        display: inline-block;
        width: 49.7%;
        background: $half-row-color-bg;
    }

    &.error {
        background: rgba($error-bg, 0.3);
    }

    &.dummy {
        background: rgba($error-bg, 0.5);
        input {
            display: none;
        }
    }
    &.section-header {
        border-left: 5px solid #ffae00;
        padding: 20px 20px 20px 15px;
        background: $lighter-gray;
        border-radius: 0;
    }

    &.preview {
        .formlabel {
            font-weight: bold;
            margin-bottom: 0;
        }
        .sub-label {
            font-weight: bold;
            color: $subheader-color;
            margin-bottom: 0;
        }
        .sub-value {
            margin: 0 0 10px;
        }

        table.cost_calculation {
            background: transparent;
            margin-bottom: 0;
            border: 0px;
            width: auto;
            thead, tbody {
                background: transparent;
            }
            tr {
                background: transparent;
            }
            td, th {
                padding-left: 0;
                padding-top: 0;
                padding-bottom: 0;
                background: transparent;
                &.number {
                    text-align: right;
                }
            }
        }


    }

    h3 {
        margin-bottom: 0;
    }

    .help-text {
        color: $info-text;
        font-size: 0.9em;
    }

    .field-group {
        display: table;
        width: auto;
        .field-inline {
            display: table-cell;
            padding: 0 10px 0 0;
            &:last-child {
                padding: 0;
            }
        }
    }

    p.error {
        color: $error-color;
    }

    p.value {
        margin: 10px 0 0;
    }

    .debug-formblock {
        position: absolute;
        bottom: 0px;
        right: 0px;
        padding: 5px;
        font-size: 10px;
        color: rgb(170, 170, 170);
    }
    &.radio, &.yesnodate, &.phd, &.yesnochar {
        ul {
            list-style: none;
            margin: 0;
        }
    }
}

.hidden_file {
    display: none;
}

.summary-row {
    // Add extra margin since the buttons lack it
    margin-bottom: $global-margin;
    .summary-text {
        @include vertical-align;
        text-align: right;
        font-weight: bold;
    }
    .cc-summary-result {
        @include form-element;
        display: block;
        font-family: $input-font-family;
        font-size: $input-font-size;
        font-weight: $input-font-weight;
        background: $input-background-disabled;
        border: $input-border-focus;
        box-shadow: $input-shadow-focus;
        radius: $input-radius;
        color: $input-color;
        font-weight: bold;

        @include vertical-align;
    }
    .summary-btn {
        @include vertical-align;
        margin-bottom: 0;

    }
}

.summary-text-preview, .cc-summary-result-preview {
    font-weight: bold;
}

.number {
    text-align: right;
}

.right-align {
    text-align: right;
}

.left-align {
    text-align: left;
}

// Special cases
#id_tidigare_reseanslag {
    height: 5rem;
}
