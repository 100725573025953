.cookie-consent--box {
    text-align: left;
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    background: rgba($black, .8);
    color: $light-gray;
    padding: 10px;
    a {
        text-decoration: underline;
        color: $light-gray;
    }
    .button {
        margin: 0 0 0 .7rem;
        text-decoration: none;
    }
}
