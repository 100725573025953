/***
 * Icons:
 */

.svg-icon {
    position: relative;
    display: inline-block;
    width: 32px;
    height: 32px;
    vertical-align: middle;
    fill: currentColor;
}

.icon-inline {
    width: 16px;
    height: 16px;
    display: inline-block;
    vertical-align: text-bottom;
    fill: currentColor;
}

.success {
    fill: $success-color;
}

.warning {
    fill: $warning-color;
}

.error {
    fill: $error-color;
}

.comment-button {
    position: fixed;
    bottom: rem-calc(15);
    left: rem-calc(15);
    border-radius: 50%;
    background: $anchor-color;
    color: $white;
    padding: rem-calc(10);
    cursor: pointer;
    .comment-text {
        display: none;
    }
    &:hover {
        border-radius: 32px;
        .comment-text {
            display: inline;
        }
    }
}
