@import "colors";

@mixin ul_list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

@mixin striped-background {
    background-image: repeating-linear-gradient(
        45deg,
        rgba($stripes-1, 0.07),
        rgba($stripes-1, 0.07) 40px,
        rgba($stripes-2, 0.07) 40px,
        rgba($stripes-2, 0.07) 80px
    ) !important;
}

// Clearfix
// --------
// For clearing floats like a boss h5bp.com/q
@mixin clearfix2 {
    *zoom: 1;
    &:before,
    &:after {
        display: table;
        content: "";
        // Fixes Opera/contenteditable bug:
        // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
        line-height: 0;
    }
    &:after {
        clear: both;
    }
}

@mixin box-shadow ($shadow: 0 0 5px rgba(0,0,0,0.2)){
    box-shadow: $shadow;
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
}

@mixin vertical-align {
    // Taken from http://zerosixthree.se/vertical-align-anything-with-just-3-lines-of-css/
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.no-margin {
    // Used for buttons where the bottom margin is not needed
    margin-bottom: 0;
}

.toggle-history:before {
    float: left !important;
    content:"+";
    padding-right: 5px;
}

.toggle-history.active:before {
    float: left !important;
    content:"-";
    padding-right: 9px;
}
