// Klass list table formatting
.klasslist {
    th {
        text-align: left;
        h2 {
            margin: 20px 0 10px 0;
        }
        h3 {
            margin: 0 0 10px 0;
        }
    }
}

// Generic table formats
table {
    &.auto-width {
        width: auto;
    }
    th, td {
        text-align: left;
        &.number {
            text-align: right;
        }
        &.center {
            text-align: center;
        }

    }
}


// Table of applications
table.application-list {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    h3 {
        margin: 0;
    }
    tbody tr {
        &.status-review-done {
            // Color is taken from $foundation-palette(success)
            background-color: scale-color($success-color, $lightness: 85%);
            &:nth-child(even) {
                background-color: scale-color($success-color, $lightness: 90%);
            }
        }
        &._collapsed {
            display: none;
        }
    }
    th {
        text-align: left;
        word-wrap: break-word;
        vertical-align: bottom;
    }
    th, td {
        padding: 3px 4px;
        // default width
        width: 20%;
    }
    td {
        padding: 10px 4px;
    }
    tr.no-alternate-color {
        background: #ffffff;
    }
    .title {
        width: 40%;
        text-overflow: ellipsis;
    }
    .date {
        width: 20%;
    }
    // th.date {
    //     text-overflow: ellipsis;
    //     white-space: nowrap;
    //     overflow: hidden;
    // }
    .number {
        text-align: right;
    }
    .truncate {
        white-space: nowrap;
        text-overflow: ellipsis;
        display: block;
        overflow: hidden;
    }

    // The columns
    th {
        &.id {
            width: 8%;
        }
        &.pn {
            width: 12%;
        }
        &.name {
            width: 27%;
        }
        &.amount {
            width: 10%;
        }
        &.inspektor {
            width: 25%;
        }
    }
}

.publications {
    td {
        padding: 10px;
        vertical-align: top;
        .img-container {
            display: inline-block;
        }
        h3 {
            margin-top: 0;
            margin-bottom: 0.3em;
        }
    }
}

// Responsive tables
@include breakpoint(small only) {
    .application-list {
        thead {
            display: none;
        }

        tr {
            display: block;
            border: 1px solid $medium-gray;
            margin-bottom: rem-calc(10);
            padding: rem-calc(10);
        }

        td {
            display: block;
            width: 100%;
            text-align: left;
            &.right, &.number, &.center {
                // Disable right alignment
                text-align: left;
            }
            &:first-child {
                padding: 0 4px 10px;
            }
        }

        td:before {
            content: attr(data-label);
            display: block;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
}
