
/***
 * Debug tools:
 */

/**
 * Debug box:
 * `.debug-userstatus` -
 * Shows a box with the current user information
 *
 *     @example
 *     <div class="debug-userstatus">
 *         <table>
 *             <tr>
 *                 <td>User</td><td>{{ user.pk }}</td>
 *             </tr>
 *             <tr>
 *                 <td>Applicant</td><td>{{ user.has_applicant_profile }}</td>
 *             </tr>
 *             <tr>
 *                 <td>Staff</td><td>{{ user.is_staff }}</td>
 *             </tr>
 *             <tr>
 *                 <td>Board member</td><td>{{ user.has_boardmember_profile }}</td>
 *             </tr>
 *         </table>
 *     </div>
 *
 */

.sg-canvas .debug-userstatus {
    position: relative !important;
}

.debug-userstatus {
    position: fixed;
    display: inline-block;
    z-index: 100;
    bottom: 20px;
    right: 20px;
    border: 1px solid rgba(0,0,0,0.7);
    background: rgba(255,255,255,0.7);
    padding: 10px;
    color: rgba(0,0,0,0.7);
    font-size: 0.7rem;
    max-width: 26vw;
    table {
        margin-bottom: 0;
        tbody {
            background: transparent;
            tr:nth-child(2n) {
                background-color: transparentize($table-striped-background, 0.3);
            }
            td {
                &:not(:first-of-type) {
                    word-break: break-all;
                }
                padding: 4px;
            }
        }

    }
}
