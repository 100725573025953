.super-navigation {
    padding: rem-calc(5) 0;
}

.super-navigation-block {
    float: right;
    height: 40px;
    line-height: 40px;
    margin-left: 20px;
    .current {
        color: #aaaaaa;
        text-decoration: none;
    }
}

// Log in section
ul.user {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: inherit;
    li {
        display: block;
        float: left;
        margin-left: 0.7em;
    }

}

.top-navigation, .sub-navigation, .sidebar-navigation {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0;
}

.top-navigation {
    overflow: auto;
    background: $nav-bg-color;
    li {
        display: block;
        float: left;
        height: 45px;
        line-height: 25px;
        a {
            display: block;
            padding: 10px;
            text-decoration: none;
            color: $nav-link-color;
        }
        &:hover {
            border-top: 3px solid $nav-link-color;
            line-height: 20px;
            background: $nav-bg-color-hover;
            a { color: $nav-link-color-hover; }
        }
        &.selected, &.ancestor {
            background: $nav-bg-color-active;
            border-top: 3px solid $nav-tab-top-color;
            font-weight: bold;
            a { color: $nav-link-color-active; }
            line-height: 20px;
        }
        &.search {
            display: flex;
            flex-direction: row;
            align-items: center;
            float: right;
            margin-right: .2rem;
            height: 44.5px;
            &:hover {
                // Disable hover styles
                border-top-width: 0;
                line-height: 25px;
                background: transparent;
            }
            .magnifier-search {
                display: flex;
                position: relative;
                input[type='search'] {
                    margin: 0;
                    padding-right: 30px;
                }
                button {
                    margin-left: -43px;
                    padding: 0 10px 0 10px;
                }
                svg.search-icon {
                    height: 24px;
                    width: 24px;
                }
            }
        }
    }
}

.sub-navigation {
    overflow: auto;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    li {
        display: block;
        float: left;
        line-height: 25px;
        &:hover, &.selected {
            text-decoration: underline;
        }
        a {
            color: $nav-subnav-link-color;
            display: block;
            padding: 5px 10px;
            text-decoration: none;
            text-shadow: 1px 1px rgba(255,255,255,0.5);
            font-size: 14px;
        }
    }
}

.mini-breadcrumbs {
    // Used in review board section. Only one level, for example "go back to application".
    overflow: auto;
    display: block;
    font-size: 14px;
    margin-top: 1.25rem;
}

.cta-green {
    @include button-style($cta-green, auto, $white);
    color: $white !important;
}
