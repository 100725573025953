// Remodal customizations
.remodal {
    &.left {
        p, h1, h2, h3 {
            text-align: left;
        }
    }
}

@mixin file-box($size: 20px) {
    display: inline-block;
    line-height: $size;
    font-family: icomoon;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    font-size: inherit;
    height: $size;
    width: $size + 3;
    text-align: left;
    color: inherit;
}

// Used by django-filer
.file {
    display: inline-block;
    position: relative;
    text-decoration: none;
    &:before {
        @include file-box;
        content: "\e003";
    }
    &:hover:before {
        color: inherit;
    }
    &.pdf:before {
        content: "\e001";
    }
}
