/***
 * base_layout.scss:
 */

p {
    &:last-child:not(:only-child) {
        margin-bottom: 0;
    }
}

.main-content {
    // Taken from .row
    max-width: $grid-row-width;
    margin-right: auto;
    margin-left: auto;

    margin-bottom: rem-calc(20);
    padding-bottom: rem-calc(40);
    background: $white;

    // Remove shadow borders from submenu
    overflow: hidden;
}

.container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: block;
    @include clearfix;
}

.sidebar {
    border-left: 1px solid #aaaaaa;
    h2 {
        color: #555555;
        margin-top: 0;
    }
}

.sidebar-navigation {
    li {
        width: 100%;
        position: relative;
        &.selected {
            a {
                background: lighten(#dac799, 20%);
            }
        }
        a {
            display: inline-block;
            width: 100%;
            text-decoration: none;
            line-height: 1.2;
            padding: 5px 10px;
            border-radius: 4px;
        }
    }
}

footer {
    position: relative;
    margin: 5px auto 40px;
    text-align: right;
}


// Fixes for .reveal
.reveal {
    h1:first-of-type {
        margin-top: 0;
    }

    p {
        &:last-child {
            margin-bottom: $global-margin;
        }
    }
    .button {
        margin-bottom: 0;
    }
}

a.f-dl {
    // Needed for download links
    display: inline-block;
    vertical-align: text-bottom;
}
