@charset 'utf-8';
@import "foundation/foundation";
@import "foundation/settings";

@include foundation-global-styles;
@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
// @include foundation-range-input;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
// @include foundation-flex-classes;

// @include motion-ui-transitions;
// @include motion-ui-animations;

@import "components/colors";
@import "components/application_form";
@import "components/application_user";
@import "components/base_layout";
@import "components/debug";
@import "components/fonts";
@import "components/forms";
@import "components/icons";
@import "components/navigation";
@import "components/tables";
@import "components/third_party";
@import "components/utils";

@import "components/cookie_consent";
@import "components/tips_boxes";

// News
.news {
    h2 {
        color: #ffffff;
        background: rgba(23,121,186,0.8);
        border-radius: 12px;
        padding: 10px;
    }
    p {
        margin-bottom: 0.3rem;
    }
    .post {
        border-radius: 12px;
        background: rgba(23,121,186,0.1);
        margin: 0 0 20px 0;
        padding: 10px;
        h3 {
            margin-top: 0;
            margin-bottom: 0.3rem;
        }
    }
    .title {
        display: block;
    }
}

.news-date {
    font-weight: bold;
    display: block;
    font-size: rem-calc(14);
    color: $dark-gray;
}

.news-introduction {
    margin-bottom: 20px;
    color: $dark-gray;
    p {
        font-size: 120%;
    }
}

// Images
.img-container {
    padding: rem-calc(10);
    border-radius: rem-calc(5);
    display: block;
    margin-bottom: rem-calc(20);
    &.left { float: left; margin: 0 rem-calc(20) rem-calc(20) 0; }
    &.right { float: right; margin: 0 0 rem-calc(20) rem-calc(20); }
    @include box-shadow;
    @include clearfix;
}

.img-caption {
    color: #aaaaaa;
    font-size: rem-calc(14);
    font-style: italic;
    font-weight: 300;
    line-height: 1.5;
}

.library {
    h6 {
        margin-bottom: 0.2rem;
    }

    .datestamp {
        color: $dark-gray;
        font-size: rem-calc(12);
        font-style: normal;
        font-weight: 300;
        line-height: 1.5;
    }

    .no-margin {
        margin: 0;
    }
}

.custom-image-format p {
    @include clearfix;
}

.button {
    font-family: $button-font-family;
}

.controlpanel-section {
    margin-bottom: rem-calc(80);
    &:last-of-type {
        margin-bottom: 0;
    }
}

// Fix foundation .label a little
.label-inline {
    margin-left: rem-calc(12);
}

.inline-wrapper > * {
    // Used on block items to make them inline-ish
    display: inline-block;
    vertical-align: middle;
}

// Fix when generating PDF's
body.pdf {
    .applform-block {
        margin: 0;
    }
}

// Fix select2
.form-block {
    .select2-container {
        display: block !important;
        .select2-choice {
            @include form-select;
            .select2-arrow {
                border-left: 0;
                background-image: none;
                background-color: $white;
                b {
                    background-position-y: 5px;
                }
            }
        }
    }
}

// Zurb foundation fixes
//
// A fix for background shifting:
// http://foundation.zurb.com/forum/posts/38019#comment_29784
// .reveal-overlay {
//     overflow-y: hidden;
// }
// body.is-reveal-open {
//     overflow: visible;
// }

.flex-center {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    background-color: hsl(0, 0%, 98%);
    .columns {
        text-align: center;
    }
    h2 {
        margin-bottom: 3em;
    }
}
.fys__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0,0,0,0.075);;
    background-color: $white;
    margin: 3.5em 0;
    padding: 3em 1em 1em;
}
.fys__avatar {
    border: 1em solid #fafafa;
    border-radius: 50%;
    display: inline-block;
    width: 200px;
    height: 200px;
    overflow: hidden;
    background: $white;
    margin-bottom: 1.5em;
    margin-top: -9em;
    svg.fys__placeholder {
        width: 120%;
        height: 120%;
        display: block;
        position: relative;
        top: 50%;
        transform: perspective(1px) translateY(-50%) translateX(-50%);
        margin-left: 50%;
        fill: $light-gray;
    }
}

.fys__position h3 {
    margin-top: 0;
}

.search-item {
    margin-bottom: 2rem;
}

.algolia-logo {
    margin-top: -25px;
    margin-bottom: 25px !important;
    img {
        width: 70px;
    }
}
