/* Icomoon font is built using the tool at http://icomoon.io/app/ */
$IcoMoonPath: "../font";
@font-face {
    font-family: 'icomoon';
    src: url('../font/icomoon.eot');
    src: url('../font/icomoon.eot?#iefix') format('embedded-opentype'),
        url('../font/icomoon.woff') format('woff'),
        url('../font/icomoon.ttf') format('truetype'),
        url('../font/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

// @import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i);

// Local implementation of Google Fonts
@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: normal;
  src: url('../font/Open-Sans-300/Open-Sans-300.eot');
  src: url('../font/Open-Sans-300/Open-Sans-300.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Light'),
       local('Open-Sans-300'),
       url('../font/Open-Sans-300/Open-Sans-300.woff2') format('woff2'),
       url('../font/Open-Sans-300/Open-Sans-300.woff') format('woff'),
       url('../font/Open-Sans-300/Open-Sans-300.ttf') format('truetype'),
       url('../font/Open-Sans-300/Open-Sans-300.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: normal;
  src: url('../font/Open-Sans-regular/Open-Sans-regular.eot');
  src: url('../font/Open-Sans-regular/Open-Sans-regular.eot?#iefix') format('embedded-opentype'),
       local('Open Sans'),
       local('Open-Sans-regular'),
       url('../font/Open-Sans-regular/Open-Sans-regular.woff2') format('woff2'),
       url('../font/Open-Sans-regular/Open-Sans-regular.woff') format('woff'),
       url('../font/Open-Sans-regular/Open-Sans-regular.ttf') format('truetype'),
       url('../font/Open-Sans-regular/Open-Sans-regular.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 300;
  font-style: italic;
  src: url('../font/Open-Sans-300italic/Open-Sans-300italic.eot');
  src: url('../font/Open-Sans-300italic/Open-Sans-300italic.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Light Italic'),
       local('Open-Sans-300italic'),
       url('../font/Open-Sans-300italic/Open-Sans-300italic.woff2') format('woff2'),
       url('../font/Open-Sans-300italic/Open-Sans-300italic.woff') format('woff'),
       url('../font/Open-Sans-300italic/Open-Sans-300italic.ttf') format('truetype'),
       url('../font/Open-Sans-300italic/Open-Sans-300italic.svg#OpenSans') format('svg');
}

@font-face {
  font-family: 'Open Sans';
  font-weight: 400;
  font-style: italic;
  src: url('../font/Open-Sans-italic/Open-Sans-italic.eot');
  src: url('../font/Open-Sans-italic/Open-Sans-italic.eot?#iefix') format('embedded-opentype'),
       local('Open Sans Italic'),
       local('Open-Sans-italic'),
       url('../font/Open-Sans-italic/Open-Sans-italic.woff2') format('woff2'),
       url('../font/Open-Sans-italic/Open-Sans-italic.woff') format('woff'),
       url('../font/Open-Sans-italic/Open-Sans-italic.ttf') format('truetype'),
       url('../font/Open-Sans-italic/Open-Sans-italic.svg#OpenSans') format('svg');
}
